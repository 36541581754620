var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',[_c('CCard',{staticClass:"object-table-wrapper"},[_c('CCardHeader',[_c('strong',[_vm._v(_vm._s(_vm.$t("channel.channels")))]),_c('div',{staticClass:"card-header-actions"},[(_vm.disabledCreate && _vm.renderComponent)?_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(_vm.canCreateChannelTooltip),expression:"canCreateChannelTooltip"}],staticClass:"fake-disabled-button d-flex justify-content-center align-items-center"},[_vm._v(" "+_vm._s(_vm.$t("common.add"))+" ")]):_vm._e(),(!_vm.disabledCreate)?_c('CDropdown',{attrs:{"toggler-text":_vm.$t('common.add'),"color":"success"}},[_c('div',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.tooltipCreate,
                placement: 'top',
                visible: _vm.disabledCreate,
              }),expression:"{\n                content: tooltipCreate,\n                placement: 'top',\n                visible: disabledCreate,\n              }"}]},[_c('CDropdownItem',{attrs:{"disabled":_vm.disabledCreate},on:{"click":_vm.createClicked}},[_vm._v(_vm._s(_vm.$t("channel.createChannel"))+" ")])],1),_c('CDropdownItem',{on:{"click":_vm.importClicked}},[_vm._v(_vm._s(_vm.$t("channel.importChannel")))])],1):_vm._e()],1)]),_c('CCardBody',[_c('CDataTable',{attrs:{"striped":"","items":_vm.allChannels,"fields":_vm.fields,"items-per-page":10,"active-page":_vm.activePage,"pagination":{ doubleArrows: false, align: 'center' },"noItemsView":{
            noResults: _vm.$t('common.noData'),
            noItems: _vm.$t('common.noData'),
          },"hover":"","clickable-rows":"","responsive":""},on:{"page-change":_vm.pageChange,"row-clicked":_vm.itemClicked},scopedSlots:_vm._u([{key:"participants",fn:function({ item }){return [_c('td',_vm._l((item.participants),function(participant){return _c('div',{key:participant.id},[_c('span',{style:({
                    'font-style': _vm.getOrgFontStyle(participant.draft_status),
                    'text-decoration': _vm.getOrgFontDecoration(
                      participant.draft_status
                    ),
                  })},[_vm._v(_vm._s(participant.mspid))])])}),0)]}},{key:"update_status",fn:function({ item }){return [_c('td',[_c('CBadge',{attrs:{"color":_vm.channelStatuses[item.status || _vm.channelStatus.created.code][
                    item.update_status
                  ].style}},[_vm._v(" "+_vm._s(_vm.channelStatuses[item.status || _vm.channelStatus.created.code][ item.update_status ].text)+" ")])],1)]}},{key:"delete",fn:function({ item }){return [_c('td',[_c('CButton',{staticClass:"p-0 m-0",attrs:{"size":"lg"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deleteChannelById(item.id)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }