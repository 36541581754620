/*

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

*/


const draftStatus = {
  added: {code: 'added', text: 'added'},
  existed: {code: 'existed', text: 'existed'},
  deleted: {code: 'deleted', text: 'deleted'},
}

export default {
  draftStatus: draftStatus,
}