<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CCard class="object-table-wrapper">
        <CCardHeader>
          <strong>{{ $t("channel.channels") }}</strong>
          <div class="card-header-actions">
            <div
              v-if="disabledCreate && renderComponent"
              v-c-tooltip="canCreateChannelTooltip"
              class="fake-disabled-button d-flex justify-content-center align-items-center"
            >
              {{ $t("common.add") }}
            </div>

            <CDropdown
              v-if="!disabledCreate"
              :toggler-text="$t('common.add')"
              color="success"
            >
              <div
                v-c-tooltip="{
                  content: tooltipCreate,
                  placement: 'top',
                  visible: disabledCreate,
                }"
              >
                <CDropdownItem @click="createClicked" :disabled="disabledCreate"
                  >{{ $t("channel.createChannel") }}
                </CDropdownItem>
              </div>
              <CDropdownItem @click="importClicked">{{
                $t("channel.importChannel")
              }}</CDropdownItem>
            </CDropdown>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            striped
            :items="allChannels"
            :fields="fields"
            :items-per-page="10"
            :active-page="activePage"
            :pagination="{ doubleArrows: false, align: 'center' }"
            :noItemsView="{
              noResults: $t('common.noData'),
              noItems: $t('common.noData'),
            }"
            @page-change="pageChange"
            hover
            clickable-rows
            @row-clicked="itemClicked"
            responsive
          >
            <template #participants="{ item }">
              <td>
                <div
                  v-for="participant in item.participants"
                  :key="participant.id"
                >
                  <span
                    :style="{
                      'font-style': getOrgFontStyle(participant.draft_status),
                      'text-decoration': getOrgFontDecoration(
                        participant.draft_status
                      ),
                    }"
                    >{{ participant.mspid }}</span
                  >
                </div>
              </td>
            </template>

            <template #update_status="{ item }">
              <td>
                <CBadge
                  :color="
                    channelStatuses[item.status || channelStatus.created.code][
                      item.update_status
                    ].style
                  "
                >
                  {{
                    channelStatuses[item.status || channelStatus.created.code][
                      item.update_status
                    ].text
                  }}
                </CBadge>
              </td>
            </template>

            <template #delete="{ item }">
              <td>
                <CButton
                  @click.stop.prevent="deleteChannelById(item.id)"
                  size="lg"
                  class="p-0 m-0"
                >
                  <CIcon name="cil-trash" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import utilMessages from "../../../utils/messages";
import { mapGetters, mapMutations, mapActions } from "vuex";

import orgConstants from "../organizations/constants";
import {
  ChannelUpdateStatus,
  ChannelStatuses,
  ChannelStatus,
} from "./constants";
import { NetworkUpdateStatus } from "../networks/constants";

export default {
  name: "Channels",
  data() {
    return {
      netUpdateStatus: NetworkUpdateStatus,
      channelStatuses: ChannelStatuses,
      channelStatus: ChannelStatus,
      renderComponent: true,
      fields: [
        {
          key: "code_name",
          label: this.$i18n.t("channel.channelsRow[0]"),
          _classes: "font-weight-bold",
        },
        {
          key: "blockchain_net_name",
          label: this.$i18n.t("channel.channelsRow[1]"),
        },
        { key: "participants", label: this.$i18n.t("channel.channelsRow[2]") },
        { key: "update_status", label: this.$i18n.t("channel.channelsRow[3]") },
        { key: "delete", label: " " },
      ],
      activePage: 1,
      orgStatus: orgConstants.draftStatus,
    };
  },

  async mounted() {
    this.forceRerender();
    await this.getChannels();
    await this.fetchNetworks();
  },

  computed: {
    ...mapGetters(["allChannels", "getUser", "getNetwork"]),
    channelUpdateStatus() {
      return ChannelUpdateStatus;
    },
    disabledCreate() {
      return (
        this.getUser.blockchain_net_update_status !==
          this.netUpdateStatus.success.code || !this.canCreateChannel
      );
    },
    tooltipCreate() {
      return this.getUser.blockchain_net_update_status !==
        this.netUpdateStatus.success.code
        ? this.$i18n.t("channel.notRunningOrEditing")
        : this.$i18n.t("channel.channelCreate");
    },
    currentUser() {
      return this.getUser;
    },
    canCreateChannel() {
      try {
        const currentNetwork = this.getNetwork(this.getUser.blockchain_net_id);
        return !currentNetwork
          ? false
          : currentNetwork.consortium.some(
              (item) => item.id === this.getUser.org_id
            );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
        return false;
      }
    },
    canCreateChannelTooltip() {
      return {
        content: this.$i18n.t("channel.ordererCannotCreateChannel"),
        placement: "left",
        visible: this.disabledCreate,
      };
    },
  },

  updated() {
    this.allChannels.forEach((channel) => {
      if (channel.errorMessage != null) {
        this.$toast.error(channel.errorMessage);
        this.deleteErrorMessageFromChannel(channel.id);
      }
    });
  },

  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },

  methods: {
    ...mapMutations(["deleteErrorMessageFromChannel"]),
    ...mapActions(["fetchNetworks"]),

    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },

    itemClicked(item) {
      if (item.update_status === this.channelUpdateStatus.success.code)
        this.$router.push({ path: `/channels/${item.id}` });
      else {
        if (item.config_update_transaction_id.length)
          this.$router.push({ path: `/channels/${item.id}/change-config` });
        else this.$router.push({ path: `/channels/${item.id}/draft` });
      }
    },

    createClicked() {
      this.$router.push({ path: `/channels/create` });
    },

    importClicked() {
      this.$router.push({ path: `/channels/import` });
    },

    async getChannels() {
      try {
        await this.$store.dispatch("fetchChannels", { view: "organization" });
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async deleteChannelById(id) {
      try {
        await this.$store.dispatch("deleteChannel", { id });
        this.$toast.success(this.$i18n.t("channel.channelDeleted"));
      } catch (error) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },

    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    getOrgFontStyle(orgStatus) {
      return orgStatus === this.orgStatus.added.code ? "italic" : "";
    },

    getOrgFontDecoration(orgStatus) {
      return orgStatus === this.orgStatus.deleted.code ? "line-through" : "";
    },
  },
};
</script>

<style>
.fake-disabled-button {
  background-color: #2eb85c;
  color: #fff;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  opacity: 0.65;
}
</style>
